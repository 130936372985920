import React from "react"
import { Divider, Empty, Typography } from "antd"
import "antd/dist/antd.css"

const { Title } = Typography

const NotFoundPage = () => (
    <>
        <Title>GLN Digital</Title>
        <Divider />
        <Empty description="Not found" />
    </>
)

export default NotFoundPage

